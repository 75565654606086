import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const YourInfertilitySpecialistLandingPage = () => (
  <PhysicianLandingPage
    physician="Kristen Burris"
    institution="Kristen Burris"
    referralCode="YOURINFERTILITYSPECIALIST"
    physicianURL="https://www.yourinfertilityspecialist.com/"
  />
)

export default YourInfertilitySpecialistLandingPage
